import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Card, InpText, Tag } from "../components/utilityComps.js";
import {
  InpAutoWrap,
  InputInputText,
  extract_inp,
  set_state_ob,
  set_val_inp,
  DateInput,
} from "../components/input.js";
import {
  cdelay,
  copy_clip,
  get_inp,
  getv,
  jstr,
  nils,
  iso,
} from "../utils/utils.js";
import _, { filter } from "lodash";
import {
  class_cn,
  class_text,
  get_payout_cn,
  get_payout_txt,
} from "../utils/cn_map.js";
import {
  gen_filters_from_valob,
  gen_valob_from_filters,
} from "../utils/filt.js";
import { twMerge } from "tailwind-merge";
import { RaceHRow } from "../utils/raceutils.js";
import {
  q_helper_add_customrrace,
  q_bikesearch,
  q_hstats,
  qissuccesss,
  useStepQuery,
  q_get_roundspreset_s,
  q_save_roundspreset,
  q_delete_roundspreset,
  q_helper_quick_actions,
  q_helper_get_quick_actions,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { Loader01c } from "../components/anims.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faDeleteLeft,
  faPlus,
  faRefresh,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { PopUp, PopupCloseBtn } from "../components/popup.js";

const RaceContext = createContext();
export const useRaceContext = () => useContext(RaceContext);

const racecn = {
  mainfiltbtn:
    "transition duration-500 text-[0.8rem] font-digi font-semibold  text-center mb-3 ",
};

const F_DateInput = ({ path, type = "iso" }) => {
  let { nf, set_nf } = useRaceContext();

  return (
    <DateInput
      id={`fqualinfo-${path}`}
      defaultValue={getv(nf, path)}
      setter={() => {
        let val = extract_inp(`fqualinfo-${path}`);
        if (type == "iso") val = iso(val);
        set_state_ob(nf, set_nf, path, val);
      }}
    />
  );
};

const F_Input = ({
  path,
  type,
  disp_txt = null,
  el_postprocess_fn = (i) => i,
  ...props
}) => {
  const { nf, set_nf } = useRaceContext();
  return (
    <InpText
      {...props}
      id={`fqualinfo-${path}`}
      def_val={disp_txt ? disp_txt(getv(nf, path)) : getv(nf, path)}
      setter={() => {
        let val = extract_inp(`fqualinfo-${path}`);
        if (type == "n") {
          val = parseFloat(val);
          if (nils(val)) val = null;
        }
        if (el_postprocess_fn) val = el_postprocess_fn(val);
        set_state_ob(nf, set_nf, path, val);
      }}
    />
  );
};

const F_InputTextAr = ({
  path,
  type,
  el_postprocess_fn = (i) => i,
  delimiter = ",",
}) => {
  const { nf, set_nf } = useRaceContext();
  return (
    <InpText
      id={`fqualinfo-${path}`}
      def_val={(getv(nf, path) || []).join(`${delimiter} `)}
      setter={() => {
        let val = extract_inp(`fqualinfo-${path}`);
        val = _.chain(val)
          .split(delimiter)
          .map((e) => e.trim())
          .map((e) => {
            if (!nils(el_postprocess_fn)) e = el_postprocess_fn(e);
            return e;
          })
          .filter((e) => !nils(e))
          .value();
        set_state_ob(nf, set_nf, path, val);
      }}
    />
  );
};

const F_OptionInput = ({ path, type = null, options, txt_fn = (o) => o }) => {
  const { nf, set_nf } = useRaceContext();
  const vals = getv(nf, path);

  return (
    <div className="fr-sc w-full flex-wrap resp-gap-1">
      {options.map((op) => {
        let active =
          type == "option"
            ? vals == op
            : type == "options" || type == "options-ar"
              ? (vals ?? []).includes(op)
              : false;
        return (
          <Tag
            key={op}
            onClick={() => {
              if (active) {
                if (type == "option") {
                  set_state_ob(nf, set_nf, path, null);
                } else if (type == "options") {
                  set_state_ob(
                    nf,
                    set_nf,
                    path,
                    (vals ?? []).filter((e) => e != op),
                  );
                } else if (type == "options-ar") {
                  set_state_ob(nf, set_nf, path, []);
                }
              } else {
                if (type == "option") {
                  set_state_ob(nf, set_nf, path, op);
                } else if (type == "options") {
                  set_state_ob(nf, set_nf, path, [...(vals || []), op]);
                } else if (type == "options-ar") {
                  set_state_ob(nf, set_nf, path, [op]);
                }
              }
            }}
            className={twMerge(
              "resp-text--2 font-mon transition duration-300",
              active ? "bg-acc0/50" : "bg-transparent text-white",
            )}
          >
            {txt_fn(op)}
          </Tag>
        );
      })}
    </div>
  );
};

const F_InputRange = ({
  path,
  type = "n",
  el_postprocess_fn = null,
  ...props
}) => {
  const { nf, set_nf } = useRaceContext();

  return (
    <div className="fr-sc">
      {["mi", "mx"].map((m) => {
        let id = `fqualinfo-${path}-${m}`;
        let valpath = `${path}.${m}`;
        return (
          <InpText
            contprops={{ className: "w-[4rem]" }}
            inpprops={{ className: "w-full" }}
            placeholder={m}
            id={id}
            def_val={getv(nf, valpath)}
            setter={() => {
              let val = extract_inp(id);
              if (type == "iso") val = iso(val);
              if (type == "n") {
                val = parseFloat(val);
                if (nils(val)) val = null;
              }
              if (el_postprocess_fn) val = el_postprocess_fn(val);
              set_state_ob(nf, set_nf, valpath, val);
            }}
            {...props}
          />
        );
      })}
    </div>
  );
};

const CriteriaSection = () => {
  const { nf, set_nf } = useRaceContext();
  const e = useMemo(() => {
    return getv(nf, "entry_filters");
  }, [jstr(nf)]);
  return (
    <div className="w-full my-2">
      <table className="w-full thintdrowp4-table">
        <tbody>
          <tr className="">
            <td>
              <span className="text-acc0">Bike Criteria</span>
            </td>
            <td>
              <Tag
                onClick={() => {
                  if (_.isEmpty(e)) {
                    set_state_ob(nf, set_nf, "entry_filters", {});
                  }
                }}
                className="fc-cc p-2 rounded-full bg-green-500 w-max mx-auto"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Tag>
            </td>
          </tr>
          {!nils(e) && (
            <>
              <tr colSpan={13}>
                <td colSpan={13}>
                  <Card className={"w-full"}>
                    <div className="fr-sc resp-gap-2 rounded-lg p-2 bg-dark w-max mr-auto">
                      <Tag>#{1}</Tag>
                      <div className="flex-1"></div>
                      <Tag
                        onClick={() => {
                          set_state_ob(nf, set_nf, "entry_filters", null);
                        }}
                        className="fc-cc p-2 rounded-full bg-red-500 w-max mx-auto"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Tag>
                    </div>
                    <table className="w-full thintdrowp4-table">
                      <tbody>
                        <tr>
                          <td>element</td>
                          <td>
                            <F_OptionInput
                              path={`entry_filters.element`}
                              type="options"
                              options={["metal", "fire", "earth", "water"]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>type</td>
                          <td>
                            <F_OptionInput
                              path={`entry_filters.type`}
                              type="options"
                              options={[
                                "genesis",
                                "morphed",
                                "freak",
                                "xclass",
                              ]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>
                            <F_OptionInput
                              path={`entry_filters.gender`}
                              type="options"
                              options={["male", "female"]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>F.No.</td>
                          <td>
                            <div className="fc-ss">
                              <F_Input
                                path={`entry_filters.fno`}
                                disp_txt={(v) => {
                                  if (nils(v)) return "";
                                  else if (_.isArray(v)) return v.join(",");
                                  else if (_.isObject(v))
                                    return `${v.mi}-${v.mx}`;
                                  else return v;
                                }}
                                el_postprocess_fn={(e) => {
                                  if (nils(e)) return null;
                                  else if (e.includes(","))
                                    return e.split(",").map((e) => parseInt(e));
                                  else if (e.includes("-"))
                                    return {
                                      mi: parseInt(e.split("-")[0]),
                                      mx: parseInt(e.split("-")[1]),
                                    };
                                  else return [parseInt(e)];
                                }}
                              />
                              <p className="resp-text--3">
                                {jstr(getv(nf, `entry_filters.fno`))}
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>skin[only signup]</td>
                          <td colSpan={12}>
                            <div className="fc-ss">
                              <F_Input
                                path={`entry_filters.skin`}
                                disp_txt={(v) => {
                                  if (v?.by == "any") return "any";
                                  if (_.isObject(v)) {
                                    return `${v.by}=${(v.ar ?? []).join(",")}`;
                                  }
                                  return "";
                                }}
                                el_postprocess_fn={(e) => {
                                  if (nils(e)) return null;
                                  else if (e == "any") return { by: "any" };
                                  else if (e.includes("=")) {
                                    let by = e.split("=")[0];
                                    let ar = e.split("=")[1].split(",");
                                    return { by, ar };
                                  }
                                  return null;
                                }}
                              />

                              <p className="resp-text--3">
                                {jstr(getv(nf, `entry_filters.skin`))}
                              </p>
                            </div>
                          </td>
                        </tr>
                        {["races_n", "win_p", "paid_races_n", "paid_win_p"].map(
                          (k) => {
                            return (
                              <React.Fragment key={k}>
                                <tr>
                                  <td>{k} [only signup]</td>
                                  <td>
                                    <div className="fr-sc">
                                      <F_Input
                                        path={`entry_filters.${k}.cb`}
                                        placeholder="cb/all"
                                        contprops={{
                                          className: "w-[4rem]",
                                        }}
                                      />
                                      <F_InputRange
                                        path={`entry_filters.${k}`}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          },
                        )}
                        <tr>
                          <td>Birthday</td>

                          <td>
                            <div className="fr-sc">
                              <F_DateInput
                                {...{ path: `entry_filters.birthday` }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <div class="h-[1rem]"></div>
    </div>
  );
};

const FiltSection = () => {
  const rcon = useRaceContext();
  const { filt, set_filt, clear_filt, submit, valfilt, nf } = rcon;
  const inpargs = {
    fkey: "open",
    filters: filt,
    set_filters: set_filt,
  };
  const fee_mode = getv(filt, "fee_mode.vals");

  const [spac_resp, set_spac_resp] = useState(null);

  const gen_valfilt = () => {
    let v = _.cloneDeep(valfilt);
    v.feeusd = 0;
    if (!_.isEmpty(nf?.entry_filters)) {
      v.r_form = {
        ...(v.r_form || {}),
        entry_filt: nf.entry_filters,
      };
    }
    return v;
  };
  const [qoacs] = useQueries([q_helper_get_quick_actions()]);
  const actions = useMemo(() => {
    return getv(qoacs, "data.result") || [];
  }, [qoacs.dataUpdatedAt]);

  const create_tripledeath_race = async (action) => {
    try {
      set_spac_resp({ loading: true, msg: "Creating Triple Death Race" });
      let doc = {
        action,
        filt: gen_valfilt(),
      };
      let resp = await q_helper_quick_actions(doc).queryFn();
      if (resp.err) throw new Error(resp.err);
      else {
        let rids = getv(resp, "result.rids");
        set_spac_resp({ msg: `created ${rids?.length} races` });
        console.log("rids", rids);
        if (_.isEmpty(rids)) return;
        copy_clip(
          rids
            .map((rid) => `https://fbike.dnaracing.run/race/${rid}`)
            .join("\n"),
        );
        for (let rid of rids) {
          let url = `https://fbike.dnaracing.run/race/${rid}`;
          console.log("open page");
          window.open(url);
          await cdelay(500);
        }
      }
    } catch (err) {
      console.log("err", err);
      if (err.message) set_spac_resp({ err: err.message });
    }
  };

  return (
    <div className="p-4">
      <div className="w-max mx-auto fr-cc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "race_name" }} />
      </div>
      <div className="w-max mx-auto fr-cc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "rvmode" }} />
      </div>
      <div className="w-max mx-auto fr-cc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "cb" }} />
      </div>
      <div className="w-max mx-auto fr-cc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "class" }} />
      </div>
      {
        <>
          <div className="grid grid-cols-2">
            <div className="col-span-1">
              <p className="text-center font-digi text-slate-500">Formats</p>
              <InpAutoWrap {...{ ...inpargs, idd: "format" }} />
            </div>
            <div className="col-span-1">
              <p className="text-center font-digi text-slate-500">Payout</p>
              <InpAutoWrap {...{ ...inpargs, idd: "payout" }} />
            </div>
            <div className="col-span-1">
              <p className="text-center font-digi text-slate-500">Gates</p>
              <InpAutoWrap {...{ ...inpargs, idd: "rgate" }} />
            </div>
            <div className="col-span-1">
              <div className="fc-cc resp-gap-2">
                <div className="">
                  <p className="text-center font-digi text-slate-500">
                    Use Start Time
                  </p>
                  <InpAutoWrap {...{ ...inpargs, idd: "use_start_time" }} />
                </div>
                <div className="">
                  <InpAutoWrap {...{ ...inpargs, idd: "is_only_signup" }} />
                </div>
                <div className="">
                  <InpAutoWrap {...{ ...inpargs, idd: "is_team" }} />
                </div>
                <div className="">
                  <InpAutoWrap {...{ ...inpargs, idd: "same_race_name" }} />
                </div>
              </div>
            </div>
          </div>
        </>
      }

      <CriteriaSection />

      <div className="fr-sc">
        <Tag
          onClick={clear_filt}
          className="text-[0.7rem] text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
        <div className="flex-1"></div>
      </div>

      <div class="m-2 resp-text--1  p-2 bg-slate-900">
        <div class="fr-ss my-1 resp-gap-1">
          <span>Quick Actions</span>

          {spac_resp && spac_resp.loading && <Loader01c size="s" />}
          {spac_resp && spac_resp.msg && (
            <span className="text-green-400">{spac_resp.msg}</span>
          )}
          {spac_resp && spac_resp.err && (
            <span className="text-red-400">{spac_resp.err}</span>
          )}
        </div>
        <div class="fr-ss my-1 resp-gap-1">
          {actions.map((a) => {
            let [action, txt] = a;
            return (
              <Tag
                onClick={() => create_tripledeath_race(action)}
                className="bg-acc0/40"
              >
                {txt}
              </Tag>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const RoundsConf = () => {
  const rcon = useRaceContext();
  const { extra, set_extra } = rcon;
  const inpargs = {
    fkey: "roundsconf",
  };
  useEffect(() => {
    set_extra({
      ...extra,
      format_inf: {
        prizepool_msg: "Payouts in Final Round",
        round_curr_status: "noninit",
        ended: false,
        round_curr: 0,
        rounds_n: 0,
        rounds: {},
      },
      r_form: {
        rounds_n: 0,
        rounds_conf: {},
      },
    });
    return () => {
      set_extra({});
    };
  }, []);

  const set_ex = (p, v) => set_state_ob(extra, set_extra, p, v);

  const inpprops = { className: "w-[6rem]" };

  const [pop, set_pop] = useState(null);
  const [popdata, set_popdata] = useState({});
  const closepopup = () => {
    set_pop(false);
    set_popdata({});
  };

  const [qorpresets] = useQueries([q_get_roundspreset_s()]);
  const rpresets = useMemo(() => {
    let ar = getv(qorpresets, "data.result") || [];
    return ar;
  }, [qorpresets.dataUpdatedAt]);

  const savepreset = async () => {
    try {
      let preset_name = get_inp("inp_preset_name");
      let rounds_n = getv(extra, "r_form.rounds_n");
      let rounds_conf = getv(extra, "r_form.rounds_conf");
      let preset = { preset_name, rounds_n, rounds_conf };
      console.log("save preset", preset);
      await q_save_roundspreset({ preset }).queryFn();
      closepopup();
      await cdelay(2000);
      qorpresets.reftch();
    } catch (err) {
      await cdelay(2000);
      set_popdata({
        ...popdata,
        err: err.message,
      });
    }
  };

  const usepreset = (p) => {
    set_extra({
      ...extra,
      format_inf: {
        ...extra.format_inf,
        rounds_n: p.rounds_n,
      },
      r_form: {
        ...extra.r_form,
        rounds_conf: p.rounds_conf,
        rounds_n: p.rounds_n,
      },
    });
    closepopup();
  };

  const delete_preset = async (p) => {
    try {
      await q_delete_roundspreset({ rpresetid: p.rpresetid }).queryFn();
      closepopup();
      await cdelay(2000);
      qorpresets.reftch();
    } catch (err) {
      await cdelay(2000);
      set_popdata({
        ...popdata,
        err: err.message,
      });
    }
  };

  const clearrconf = () => {
    set_extra({
      ...extra,
      format_inf: {
        ...extra.format_inf,
        rounds_n: 0,
      },
      r_form: {
        ...extra.r_form,
        rounds_conf: {},
        rounds_n: 0,
      },
    });
    closepopup();
  };

  return (
    <div className="p-4">
      <div className="grid grid-cols-2">
        <div className="col-span-2">
          <div className="fr-sc">
            <p className="text-center font-digi text-slate-500 px-4">Rounds</p>
            <InpText
              {...{
                id: "inp_rounds_n",
                inpprops,
                placeholder: "No of Rounds",
                def_val: getv(extra, "r_form.rounds_n") ?? "",
                setter: (v) => {
                  let n = parseInt(v);
                  set_extra({
                    ...extra,
                    format_inf: {
                      ...extra.format_inf,
                      rounds_n: n,
                    },
                    r_form: {
                      ...extra.r_form,
                      rounds_n: n,
                    },
                  });
                },
              }}
            />
            <div className="flex-1"></div>
            <Tag
              onClick={() => set_pop(true)}
              className="bg-acc0/40 text-white"
            >
              Presets
            </Tag>
            <Tag onClick={clearrconf} className="bg-red-400 text-white">
              Clear Rconf
            </Tag>
            <PopUp openstate={pop} overlayclose={false}>
              <Card className={"w-[50rem] bg-reg"}>
                <div className="fr-sc">
                  <div className="flex-1"></div>
                  <PopupCloseBtn closepopup={closepopup} />
                </div>

                <p className="resp-text--1 my-1 text-acc0 font-digi">
                  Save Preset
                </p>
                <div className="fr-cc resp-gap-2">
                  <InpText
                    {...{
                      id: "inp_preset_name",
                      placeholder: "Preset Name",
                      inpprops: {
                        className: "w-[20rem] bg-transparent",
                      },
                      contprops: {
                        className: "bg-dark",
                      },
                    }}
                  />
                  <Tag
                    onClick={() => {
                      savepreset();
                    }}
                    className="bg-acc0/40 -skew-x-12"
                  >
                    Save
                  </Tag>
                </div>

                <hr className="my-2" />

                <div className="fr-sc w-full flex-wrap">
                  <p className="resp-text--1 my-1 text-acc0 font-digi">
                    Use Preset
                  </p>
                  <Tag onClick={() => qorpresets.refetch()}>
                    <FontAwesomeIcon icon={faRefresh} />
                  </Tag>
                </div>
                {qissuccesss(qorpresets) && (
                  <div className="fr-sc resp-gap-1 w-full flex-wrap">
                    {rpresets.map((p) => {
                      return (
                        <div className="fr-sc">
                          <Tag
                            className="bg-acc0/40 -skew-x-12 resp-text--1"
                            onClick={() => usepreset(p)}
                          >
                            {p.rpresetid}-{p.preset_name}
                          </Tag>
                          <Tag
                            onClick={() => delete_preset(p)}
                            className="bg-transparent text-red-400"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Tag>
                          <div className="w-[1rem]"></div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </Card>
            </PopUp>
          </div>

          <div className="fr-sc">
            <p className="text-center font-digi text-slate-500 px-4">
              midsubrounds_delay
            </p>
            <InpText
              {...{
                id: "inp_rounds_n",
                inpprops,
                placeholder: "No of Rounds",
                def_val: getv(extra, "r_form.midsubrounds_delay") ?? "",
                setter: (v) => {
                  let n = parseInt(v);
                  set_extra({
                    ...extra,
                    r_form: {
                      ...extra.r_form,
                      midsubrounds_delay: n,
                    },
                  });
                },
              }}
            />
          </div>
          {
            <table>
              {getv(extra, "r_form.rounds_n") > 0 && (
                <thead>
                  <tr className="thintdrow">
                    <td>-</td>
                    <td>IN - each subround</td>
                    <td>no. of sub rounds</td>
                    <td>Payout</td>
                    <td>CB-[10]</td>
                    <td>adv_points</td>
                  </tr>
                </thead>
              )}
              <tbody>
                {[...new Array(getv(extra, "r_form.rounds_n") || 0)].map(
                  (e, i) => {
                    let ri = i + 1;
                    return (
                      <tr key={ri} className={twMerge("thintdrow")}>
                        <td className="text-center font-digi text-slate-500">
                          Round {ri}
                        </td>
                        <td>
                          <InpText
                            {...{
                              id: `inp_r${ri}_in`,
                              inpprops,
                              def_val:
                                getv(extra, `r_form.rounds_conf.${ri}.in`) ??
                                "",
                              setter: (v) => {
                                v = parseInt(v);
                                let rounds_conf =
                                  getv(extra, "r_form.rounds_conf") || {};
                                rounds_conf = {
                                  ...rounds_conf,
                                  [ri]: {
                                    ...(rounds_conf[ri] || {}),
                                    in: v,
                                  },
                                };
                                set_ex(`r_form.rounds_conf`, rounds_conf);
                              },
                            }}
                          />
                        </td>
                        <td>
                          <InpText
                            {...{
                              id: `inp_r${ri}_n`,
                              inpprops,
                              def_val:
                                getv(extra, `r_form.rounds_conf.${ri}.n`) ?? "",
                              setter: (v) => {
                                v = parseInt(v);
                                // set_ex(`r_form.rounds_conf.${ri}.n`, v);
                                let rounds_conf =
                                  getv(extra, "r_form.rounds_conf") || {};
                                rounds_conf = {
                                  ...rounds_conf,
                                  [ri]: {
                                    ...(rounds_conf[ri] || {}),
                                    n: v,
                                  },
                                };
                                set_ex(`r_form.rounds_conf`, rounds_conf);
                              },
                            }}
                          />
                        </td>
                        <td>
                          <InpText
                            {...{
                              id: `inp_r${ri}_payout`,
                              inpprops,
                              def_val:
                                getv(
                                  extra,
                                  `r_form.rounds_conf.${ri}.payout`,
                                ) ?? "",
                              setter: (v) => {
                                let rounds_conf =
                                  getv(extra, "r_form.rounds_conf") || {};
                                rounds_conf = {
                                  ...rounds_conf,
                                  [ri]: {
                                    ...(rounds_conf[ri] || {}),
                                    payout: v,
                                  },
                                };
                                set_ex(`r_form.rounds_conf`, rounds_conf);
                              },
                            }}
                          />
                        </td>
                        <td>
                          <InpText
                            {...{
                              id: `inp_r${ri}_cb`,
                              inpprops,
                              def_val:
                                getv(extra, `r_form.rounds_conf.${ri}.cb`) ??
                                "",
                              setter: (v) => {
                                v = parseInt(v);
                                let rounds_conf =
                                  getv(extra, "r_form.rounds_conf") || {};
                                rounds_conf = {
                                  ...rounds_conf,
                                  [ri]: {
                                    ...(rounds_conf[ri] || {}),
                                    cb: v,
                                  },
                                };
                                set_ex(`r_form.rounds_conf`, rounds_conf);
                              },
                            }}
                          />
                        </td>

                        <td>
                          <InpText
                            {...{
                              id: `inp_r${ri}_advpts`,
                              inpprops,
                              def_val:
                                getv(
                                  extra,
                                  `r_form.rounds_conf.${ri}.adv_points`,
                                ) ?? "",
                              setter: (v) => {
                                v = parseFloat(v);
                                let rounds_conf =
                                  getv(extra, "r_form.rounds_conf") || {};
                                rounds_conf = {
                                  ...rounds_conf,
                                  [ri]: {
                                    ...(rounds_conf[ri] || {}),
                                    adv_points: v,
                                  },
                                };
                                set_ex(`r_form.rounds_conf`, rounds_conf);
                              },
                            }}
                          />
                        </td>
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          }
        </div>
        <div className="col-span-1"></div>
      </div>
    </div>
  );
};

const EntruFilters = () => {
  const { filt: nf, set_filt: set_nf } = useRaceContext();
};

function CustomRace() {
  const basefilt = {};
  const [filt, set_filt] = useState({
    rvmode: {
      type: "options-only",
      path: "rvmode",
      cfilter: true,
      options: ["car", "bike", "horse"],
      vals: !nils(basefilt?.rvmode) ? basefilt?.rvmode : "bike",
      txt_fn: (o) => {
        return o;
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem]`,
      color_fn: (o) => {
        return "bg-acc0/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    class: {
      type: "options-only",
      path: "class",
      cfilter: true,
      options: [1, 2, 3, 4, 5, 50, 60, 70, 80, 90],
      vals: !nils(parseInt(basefilt?.class)) ? parseInt(basefilt?.class) : 5,
      txt_fn: (o) => {
        if (o == "my_races") return `My Races`;
        if (o == "_1v1") return `1v1`;
        return class_text(o, "T");
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem]`,
      color_fn: (o) => {
        return class_cn(o, "font-digi bg-opacity-80");
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-${class_cn(op)?.replace("bg-", "")}`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    cb: {
      type: "options",
      path: "cb",
      cfilter: true,
      options: [10, 12, 14, 16, 18, 20, 22, "all"],
      filter_exception: [],
      vals: basefilt.cb,
      txt_fn: (o) => {
        return !nils(parseInt(o)) ? `CB${o}00` : o;
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem]`,
      color_fn: (o) => {
        return "bg-acc0/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    payout: {
      type: "options-only",
      path: (r, f, filters) => {
        if (_.isEmpty(f)) return true;
        let c = 0;
        for (let p of f) {
          if (r.payout.startsWith(p)) c++;
        }
        return c !== 0;
      },
      cfilter: true,
      label: "Payout",
      show_label: false,
      options: [
        "na",
        "wta",
        "top2",
        "top3",
        "top4",
        "pity",
        "pitylow",
        "dblup",
        "hybrid",
        "heavy",
        "rounds",
      ],
      filter_exception: [],
      vals: basefilt?.payout,
      txt_fn: (o) => {
        return get_payout_txt(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem]`,
      color_fn: (o) => {
        return get_payout_cn(o);
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-purple-500`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    format: {
      type: "options-only",
      path: (r, f, filters) => {
        if (_.isEmpty(f)) return true;
        let c = 0;
        for (let p of f) {
          if (r.format.startsWith(p)) c++;
        }
        return c !== 0;
      },
      cfilter: true,
      label: "format",
      show_label: false,
      options: ["normal", "spin_n_go", "sit_n_go", "reduced", "rounds"],
      filter_exception: [],
      vals: _.isEmpty(basefilt?.format) ? ["normal"] : basefilt?.format,
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem]`,
      color_fn: (o) => {
        return "bg-acc0/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    rgate: {
      type: "inputtext",
      inptype: "n",
      path: "rgate",
      cfilter: true,
      label: "Race Gates",
      show_label: false,
      options: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      filter_exception: [],
      vals: basefilt?.rgate?.map((e) => parseInt(e)),
      txt_fn: (o) => {
        return `${o} Gates`;
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[6rem]`,
      color_fn: (o) => {
        return "bg-purple-500/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-purple-500`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    race_name: {
      type: "inputtext",
      label: "RaceName",
      cn: "bg-dark w-[19rem]",
      label_cn: "underline",
      vals: "--",
    },
    use_start_time: {
      type: "date",
      show_label: false,
      label: "",
      cn: "bg-dark w-[19rem]",
      label_cn: "underline",
      vals: "--",
    },
    is_only_signup: {
      type: "switch",
      path: "gender",
      cfilter: true,
      label: "is_only_signup?",
      show_label: true,
      vals: basefilt?.is_only_signup ?? null,
      path: "is_only_signup",
      txt_fn: (o) => {
        if (nils(o)) return "--";
        else if (o == true) return "ON";
        else return "OFF";
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `resp-text--1`,
      color_fn: (o) => {
        if (nils(o)) return "text-slate-400";
        else if (o == true) return "text-green-400";
        else return "text-red-400";
      },
      active_cn: (active, op) => {},
    },

    is_team: {
      type: "switch",
      path: "is_team",
      cfilter: true,
      label: "is_team?",
      show_label: true,
      vals: basefilt?.is_team ?? null,
      path: "is_team",
      txt_fn: (o) => {
        if (nils(o)) return "--";
        else if (o == true) return "ON";
        else return "OFF";
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `resp-text--1`,
      color_fn: (o) => {
        if (nils(o)) return "text-slate-400";
        else if (o == true) return "text-green-400";
        else return "text-red-400";
      },
      active_cn: (active, op) => {},
    },

    same_race_name: {
      type: "switch",
      path: "same_race_name",
      cfilter: true,
      label: "same_race_name?",
      show_label: true,
      vals: basefilt?.same_race_name ?? null,
      path: "same_race_name",
      txt_fn: (o) => {
        if (nils(o)) return "--";
        else if (o == true) return "ON";
        else return "OFF";
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `resp-text--1`,
      color_fn: (o) => {
        if (nils(o)) return "text-slate-400";
        else if (o == true) return "text-green-400";
        else return "text-red-400";
      },
      active_cn: (active, op) => {},
    },
  });

  const sel_rvmode = getv(filt, "rvmode.vals");
  useEffect(() => {
    console.log("sel_rvmode", sel_rvmode);
    let nfcb = _.cloneDeep(getv(filt, "cb"));
    if (sel_rvmode == "bike") {
      nfcb.vals = [];
      nfcb.options = [10, 14, 18, 22];
    } else {
      nfcb.vals = [];
      nfcb.options = [10, 12, 14, 16, 18, 20, 22, "all"];
    }
    // setTimeout(() => {
    set_state_ob(filt, set_filt, "cb", nfcb);
    // }, 1000);
  }, [sel_rvmode]);

  const [extra, set_extra] = useState({});
  const [nf, set_nf] = useState({});

  const [hids, set_hids] = useState([]);

  const valfilt = useMemo(() => {
    let race = gen_valob_from_filters(filt);
    race = {
      ...race,
      ..._.cloneDeep(extra),
    };
    console.log("race", race);
    return race;
  }, [filt, extra]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
    set_hids([]);
  };

  const qshs = useStepQuery({
    q_: q_hstats,
    par_ar: (hids || []).map((hid) => [
      { hid, cb: valfilt.cb, class: valfilt.class },
    ]),
    lim: 3,
  });

  const inp = {
    anothervault: "inp_anothervault",
    searchtxt: "inp_searchtxt",
  };

  const [refreshc, set_refreshc] = useState(0);
  const searchtxt = useMemo(() => {
    let v = get_inp(inp.searchtxt);
    return v;
  }, [refreshc]);
  const [qosearch] = useQueries([q_bikesearch({ name: searchtxt, limit: 10 })]);
  const searchres = useMemo(() => {
    let ar = getv(qosearch, "data.result") || [];
    return _.chain(ar).map("hid").uniq().value();
  }, [qosearch.dataUpdatedAt]);
  const qshs_search = useStepQuery({
    q_: q_hstats,
    par_ar: (_.map(searchres) || []).map((hid) => [
      { hid, cb: valfilt.cb, class: valfilt.class },
    ]),
    lim: 3,
  });

  const add_bike = (hid) => {
    let nhids = _.chain([...hids, hid])
      .compact()
      .uniq()
      .value();
    set_hids(nhids);
  };
  const rem_bike = (hid) => {
    let nhids = _.chain([...hids])
      .filter((e) => e != hid)
      .compact()
      .uniq()
      .value();
    set_hids(nhids);
  };

  const submit = async () => {
    let race = _.cloneDeep(valfilt);
    race.feeusd = 0;
    if (!_.isEmpty(nf?.entry_filters)) {
      race.r_form = {
        ...(race.r_form || {}),
        entry_filt: nf.entry_filters,
      };
    }
    // race = { ...race, ...nf };
    let doc = { race, hids };
    console.log("submit", doc);

    // if (doc.hids.length !== race.rgate) {
    //   return;
    // }
    let resp = await q_helper_add_customrrace(doc).queryFn();

    console.log("q_helper_add_customrrace", resp);
    let rids = getv(resp, "result.rids");
    console.log("rids", rids);
    if (_.isEmpty(rids)) return;
    copy_clip(
      rids.map((rid) => `https://fbike.dnaracing.run/race/${rid}`).join("\n"),
    );
    for (let rid of rids) {
      let url = `https://fbike.dnaracing.run/race/${rid}`;
      console.log("open page");
      window.open(url);
      await cdelay(500);
    }

    // clear_filt();
    set_hids([]);
  };

  const push_bulk = () => {
    try {
      let id = "inp_bulk_text";
      let txt = extract_inp(id);
      txt = _.chain(txt)
        .split("\n")
        .map((e) => e.trim())
        .compact()
        .map((e) => parseInt(e))
        .uniq()
        .value();
      let nhids = [...hids, ...txt];
      nhids = _.uniq(nhids);
      set_hids(nhids);
      set_val_inp(id, "");
    } catch (err) {
      console.log(err);
    }
  };

  const rcon = {
    filt,
    set_filt,
    extra,
    set_extra,
    valfilt,
    clear_filt,
    submit,
    nf,
    set_nf,
  };

  return (
    <RaceContext.Provider value={rcon}>
      <div className="page-wrapper">
        <div className="mx-auto max-w-[80rem]">
          <div className="h-[2rem]"></div>
          <>
            <Card className={"max-w-full px-4"}>
              <FiltSection />
              {valfilt.format == "rounds" && <RoundsConf />}
              <div className="fr-sc">
                <div className="flex-1"></div>
                {valfilt.rgate !== hids.length && (
                  <>
                    <Tag className="resp-text--2">
                      {hids.length}/{valfilt.rgate} Bikes
                    </Tag>
                  </>
                )}
                <Tag onClick={submit} className="bg-green-500">
                  Submit
                </Tag>
              </div>
            </Card>

            <Card className={"w-full bg-gray-700"}>
              <p className="my-2">Search</p>
              <div className="fr-cc">
                <div className="flex-1">
                  <InpText
                    {...{
                      id: inp.searchtxt,
                      placeholder: "Enter Bike ID of Bike Name",

                      contprops: {
                        className: "w-full",
                      },
                      inpprops: {
                        className: "w-full",
                      },
                      setter: () => {
                        set_refreshc(refreshc + 1);
                      },
                    }}
                  />
                </div>
                {qosearch.isLoading && <Loader01c size="s" />}
              </div>

              <div className="h-[20rem] overflow-auto w-full">
                {(_.map(searchres) || []).map((hid, idx) => {
                  let q = qshs_search.qs[idx];

                  let h = getv(q, "data.result");
                  return (
                    <div key={hid} className="fr-sc my-2">
                      {!qissuccesss(q) ? (
                        <Card className={"w-full bg-reg"}>Bike-{hid}</Card>
                      ) : (
                        <RaceHRow {...{ hid, h, key: hid }} />
                      )}
                      <Tag
                        onClick={() => add_bike(hid)}
                        className={"bg-green-400 text-black"}
                      >
                        <FontAwesomeIcon icon={faAdd} />
                      </Tag>
                    </div>
                  );
                })}
              </div>
            </Card>

            <Card className={"w-full bg-gray-700 h-[15rem] overflow-auto"}>
              <div className="fr-sc">
                <Tag onClick={push_bulk} className="bg-acc0/40 text-white">
                  Push Bulk
                </Tag>
              </div>
              <textarea
                className="bg-dark text-white w-full h-[10rem] p-1"
                id="inp_bulk_text"
              ></textarea>
            </Card>

            <Card className={"w-full  bg-gray-700"}>
              <div className="my-2 fr-cc gap-2">
                <p>Added Bikes</p>
                <div className="flex-1"></div>
                <span>{hids.length}</span>
                <span>/</span>
                <span>{valfilt.rgate} Gates</span>
              </div>
              {hids.map((hid, idx) => {
                let q = qshs.qs[idx];
                let h = getv(q, "data.result");
                return (
                  <div key={hid} className="fr-sc my-2">
                    {!qissuccesss(q) ? (
                      <Card className={"w-full bg-reg"}>Bike-{hid}</Card>
                    ) : (
                      <RaceHRow {...{ hid, h, key: hid }} />
                    )}
                    <Tag
                      onClick={() => rem_bike(hid)}
                      className={"bg-red-400 text-black"}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Tag>
                  </div>
                );
              })}
            </Card>
            <div className="h-[5rem]"></div>
          </>
        </div>
      </div>
    </RaceContext.Provider>
  );
}

export default CustomRace;
